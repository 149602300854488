import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "business-trigger-wrap"
};
import Image from "../base/Image.vue";
export default {
  __name: 'BusinessTrigger',
  props: {
    businessTrigger: {
      type: Object,
      default: null
    }
  },
  setup: function setup(__props) {
    var props = __props;
    function handleClick(item) {
      if (item.is_redirect && item.redirect_url) {
        location.href = item.redirect_url;
      }
    }
    return function (_ctx, _cache) {
      var _props$businessTrigg;
      return (_props$businessTrigg = __props.businessTrigger) !== null && _props$businessTrigg !== void 0 && (_props$businessTrigg = _props$businessTrigg.banner) !== null && _props$businessTrigg !== void 0 && _props$businessTrigg.is_open ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.businessTrigger.banner.items, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "business-trigger-img-wrap"
        }, [item.image ? (_openBlock(), _createBlock(Image, {
          key: 0,
          src: item.image,
          class: "business-trigger-img",
          "oss-stylename": "type_27",
          onClick: function onClick($event) {
            return handleClick(item);
          }
        }, null, 8, ["src", "onClick"])) : _createCommentVNode("", true)]);
      }), 128))])) : _createCommentVNode("", true);
    };
  }
};