import { Persistent } from "./cache/persistent";

import md5 from "js-md5";

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function genUid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * 构造http请求的公参
 * @param {Object} query 原始公参对象
 * @param {Object} options 配置项
 * @return {Object} 带有signature的公参对象
 */
export function buildPublicParams(query, options = {}) {
  const commonParams = {
    ...query,
  };
  // 处理keys：按A-Z排序、移除值为空的字段
  const keys = Object.keys(commonParams)
    .filter((key) => String(commonParams[key]))
    .sort();

  // 拼装公参
  let keysString = keys.map((key) => `${key}=${commonParams[key]}`).join("&");
  keysString += `&secret=${options.apiSecret}`;

  // 计算signature
  const signature = md5(encodeURI(keysString)).toUpperCase();

  return {
    ...commonParams,
    signature,
  };
}

export function getUid() {
  let uid = Persistent.getLocal("__gid__");
  if (!uid) {
    uid = genUid();
    Persistent.setLocal("__gid__", uid);
  }
  return uid;
}

/**
 * isOverOpenTime 检测是否超出营业时间
 * @param {*} businessHours 营业时间business hours
 * @param {*} time 检测的时间
 * @returns {*} isOver
 */
export function isOverOpenTime(businessHours = [], time) {
  let isOver = true;
  const now = time || new Date();
  const day = now.getDay();
  const hour = now.getHours();
  const minute = now.getMinutes();

  // 检查时间是否超出
  const checkHours = (hours, { hour, minute }) => {
    let isHoursOver = true;

    for (let i = 0; i < hours.length; i++) {
      const item = hours[i];
      const start = item.start_at.split(":");
      const end = item.end_at.split(":");
      const startHour = parseInt(start[0]);
      const startMin = parseInt(start[1]);
      const endHour = parseInt(end[0]);
      const endMin = parseInt(end[1]);

      if (
        (startHour < hour || (startHour === hour && startMin <= minute)) &&
        (endHour > hour || (endHour === hour && endMin >= minute))
      ) {
        isHoursOver = false;
        break;
      }
    }

    return isHoursOver;
  };

  for (let i = 0; i < businessHours.length; i++) {
    const item = businessHours[i];
    let isOneOver = true;

    switch (item.type) {
      case "every_day":
        isOneOver = checkHours(item.hours, { hour, minute });
        break;
      case "sunday_to_thursday":
        if (day > 4) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "monday_to_friday":
        if (day < 1 || day > 5) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "saturday_to_sunday":
        if (day !== 6 && day !== 0) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "sunday":
        if (day !== 0) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "monday":
        if (day !== 1) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "tuesday":
        if (day !== 2) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "wednesday":
        if (day !== 3) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "thursday":
        if (day !== 4) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "friday":
        if (day !== 5) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      case "saturday":
        if (day !== 6) {
          isOneOver = true;
        } else {
          isOneOver = checkHours(item.hours, { hour, minute });
        }
        break;
      default:
        break;
    }

    if (!isOneOver) {
      isOver = false;
      break;
    }
  }

  return isOver;
}

/**
 * 把数据表的type-hours结构转为day-hours结构
 * @param {Array} arrBusinessHours business_hours,形如[{type: 'monday_to_friday', hours: [{start_at: "07:27", end_at: "23:27"}]}]
 */
export function toDayBusinessHoursMap(arrBusinessHours) {
  if (!(arrBusinessHours && Array.isArray(arrBusinessHours))) {
    return {};
  }

  // 定义type-day映射
  const type2DayMap = {
    every_day: [0, 1, 2, 3, 4, 5, 6],
    monday_to_friday: [1, 2, 3, 4, 5],
    sunday_to_thursday: [0, 1, 2, 3, 4],
    saturday_to_sunday: [0, 6],
    sunday: [0],
    monday: [1],
    tuesday: [2],
    wednesday: [3],
    thursday: [4],
    friday: [5],
    saturday: [6],
  };

  // 转换
  const resMap = {
    0: { type: "Sunday", hours: [], str: "" },
    1: { type: "Monday", hours: [], str: "" },
    2: { type: "Tuesday", hours: [], str: "" },
    3: { type: "Wednesday", hours: [], str: "" },
    4: { type: "Thursday", hours: [], str: "" },
    5: { type: "Friday", hours: [], str: "" },
    6: { type: "Saturday", hours: [], str: "" },
  };
  const getNumByTime = function (time) {
    const num = time.replace(":", "");
    return Number(num);
  };
  const appendZero = function (time) {
    return time.length === 4 ? "0" + time : time;
  };
  const countTime = function (list) {
    const arr = [];
    list.sort();
    list.forEach((i) => {
      arr.push(`${appendZero(i.start_at)} ~ ${appendZero(i.end_at)}`);
    });
    return arr.join(", ");
  };
  arrBusinessHours.forEach((bhItem) => {
    // 单独的配置
    type2DayMap[bhItem.type].forEach((item) => {
      // 每个配置里面的有效日期
      if (resMap[item].hours.length) {
        const results = {};
        bhItem.hours.forEach((a, b) => {
          results[b] = {
            flag: true,
            data: a,
          };
        });
        resMap[item].hours.forEach((oldHour) => {
          // 有效日期已有的营业时间
          bhItem.hours.forEach((newHour, index) => {
            // 新的营业时间
            const newStart = getNumByTime(newHour.start_at);
            const oldStart = getNumByTime(oldHour.start_at);
            const newEnd = getNumByTime(newHour.end_at);
            const oldEnd = getNumByTime(oldHour.end_at);
            if (newStart >= oldStart && newStart <= oldEnd) {
              newEnd > oldEnd && (oldHour.end_at = newHour.end_at);
              results[index] = { flag: false };
            } else if (newEnd >= oldStart && newEnd <= oldEnd) {
              newStart < oldStart && (oldHour.start_at = newHour.start_at);
              results[index] = { flag: false };
            } else if (newStart <= oldStart && newEnd >= oldEnd) {
              oldHour.start_at = newHour.start_at;
              oldHour.end_at = newHour.end_at;
              results[index] = { flag: false };
            } else {
              // flagData = newHour
            }
          });
        });
        for (const key in results) {
          if (results[key].flag) {
            resMap[item].hours.push(results[key].data);
          }
        }
      } else {
        resMap[item].hours = JSON.parse(JSON.stringify(bhItem.hours));
      }
      // resMap[item].hours = [...resMap[item].hours, ...bhItem.hours]
    });
  });

  for (const key in resMap) {
    resMap[key].str = countTime(resMap[key].hours);
  }

  return resMap;
}

export function defaultTextFilter(value) {
  // default多语言小写文本
  const defaultLowercaseLang = [
    "默认",
    "default",
    "bawaan",
    "ค่าเริ่มต้น",
    "varsayılan",
    "mặc định",
  ];

  return defaultLowercaseLang.includes(value?.toLowerCase() || "") ? "" : value;
}

export function nth(d) {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return "th";
  switch (last % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

/**
 * 使用阿里云图片压缩功能
 * @param {String} imgUrl 图片路径
 * @param {String} stylename 图片样式参数
 */
export function ossStyleProcess(imgUrl, stylename) {
  if (!imgUrl || !stylename) {
    return imgUrl;
  }

  if (imgUrl.indexOf("http") === -1) {
    return imgUrl;
  }

  if (imgUrl.indexOf("x-oss-process") !== -1) {
    return imgUrl;
  }

  return `${imgUrl}${
    imgUrl.indexOf("?") === -1 ? "?" : "&"
  }x-oss-process=style/${stylename}`;
}

/**
 * 在原有url上拼接query参数，返回构造后的url
 */
export function addQueryString(url, queryString) {
  if (!url || !queryString) {
    return url;
  }

  if (url.indexOf(queryString) !== -1) {
    return url;
  }

  const lastPathPart =
    url.lastIndexOf("/") > -1 ? url.slice(url.lastIndexOf("/")) : url;
  return `${url}${lastPathPart.indexOf("?") === -1 ? "?" : "&"}${queryString}`;
}

/**
 * 检查是否在webview内，是则提示在浏览器中打开，目前用于解决webview内无法谷歌登录的问题
 */
export function checkIfInAppWebview(ua) {
  if (!ua) return false;

  const rules = [
    // facebook
    function isInFacebookApp() {
      return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
    },

    // instagram
    function isInstagram() {
      return /instagram/i.test(ua);
    },
  ];

  return rules.some((rule) => {
    return rule();
  });
}

// 判断平台,传参目前支持"ios"和"android"
export function checkPlatform(platform) {
  const u = navigator.userAgent;
  if (platform === "android" && /android/i.test(u)) {
    return true;
  }
  if (platform === "ios" && /iPhone|iPad|iPod|iOS|Mac OS/i.test(u)) {
    return true;
  }
  return false;
}

// 判断是否为iphone的safari
export function getIsIphoneSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (
    ua.indexOf("applewebkit") > -1 &&
    ua.indexOf("mobile") > -1 &&
    ua.indexOf("safari") > -1 &&
    ua.indexOf("linux") === -1 &&
    ua.indexOf("android") === -1 &&
    ua.indexOf("chrome") === -1 &&
    ua.indexOf("ios") === -1 &&
    ua.indexOf("browser") === -1
  ) {
    return true;
  } else {
    return false;
  }
}

// 获取ios版本
export function getIosVersion() {
  var ua = navigator.userAgent.toLowerCase();
  var version = null;
  if (ua.indexOf("like mac os x") > 0) {
    var reg = /os [\d._]+/gi;
    var v_info = ua.match(reg);
    version = (v_info + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, "."); //得到版本号9.3.2或者9.0
    version = Number(version.split(".").slice(0, 2).join(".")); // 取前两位的版本
  }

  return version;
}
