import "core-js/modules/es.array.join.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "outofstock-item-list"
};
var _hoisted_2 = {
  class: "info"
};
var _hoisted_3 = {
  class: "name"
};
var _hoisted_4 = {
  class: "text"
};
var _hoisted_5 = {
  class: "num"
};
var _hoisted_6 = {
  class: "attr"
};
var _hoisted_7 = {
  class: "price"
};
import Common from "@/components/dialog/src/Common.vue";
import Image from "../base/Image.vue";
import { computed } from "vue";
import { priceUtil } from "@op/shared";
export default {
  __name: 'OutOfStockDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dishes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ["update:visible", "confirm"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var show = computed({
      get: function get() {
        return props.visible;
      },
      set: function set(val) {
        emit("update:visible", val);
      }
    });
    var onConfirm = function onConfirm() {
      show.value = false;
      emit("confirm");
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(Common, {
        visible: show.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return show.value = $event;
        }),
        title: "Order Fialed",
        desc: "".concat(__props.dishes.length, " Products out of stock or updated"),
        "ok-text": "Remove & Reorder",
        "on-ok": onConfirm,
        "hide-bottom-shadow": true,
        "without-cancel": true
      }, {
        content: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dishes, function (item) {
            return _openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "item"
            }, [_createVNode(Image, {
              "oss-stylename": "type_3",
              class: "img",
              src: item.img,
              alt: ""
            }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_5, "x" + _toDisplayString(item.quantity), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.attrs.join(",")), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(item.price, "")), 1)])]);
          }), 128))])];
        }),
        _: 1
      }, 8, ["visible", "desc"]);
    };
  }
};